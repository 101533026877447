
@import 'variables';
@import 'mixings';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600&display=swap');


body {
  font-family: $primary-font !important;
}

#root {
  background-color: $base-color;
}
//Font
h1, h2, h3, h4, h5, p {
 font-family:  $primary-font;
 color: $light-gray;
}

a {
  color: $light-gray !important;
}

a:hover {
  color: $onbase-color !important;
}

a:active {
  color: $onbase-color !important;
}


.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link 
{
  color: $onbase-color !important;
}
// Backgrounds Color
.bg-base {
  background: $base-color;
}

.bg-onbase {
  background: $onbase-color;
}

.bg-lightgray {
  background: $light-gray;
}

.bg-mediumgray {
  background: $medium-gray;
}

.bg-darkgray {
  background: $dark-gray;
}

.bg-base-gradient {
  background: $base-gradient;
}

//Font Color 

.color-base {
  color: $base-color;
}

.color-onbase {
  color: $onbase-color;
}

.color-lightgray {
  color: $light-gray;
}

.color-mediumgray {
  color: $medium-gray;
}

.color-darkgray {
  color: $dark-gray;
}

//Swiper

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}


.swiper-button-next, .swiper-button-prev {
  color: $light-gray !important;
}

.swiper-pagination-bullet-active {
  background-color: $light-gray !important;
}

.bg-slide { 
  background-image: url('../images/SlideOne.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 250px 0px
}

.bg-slide-2 { 
  background-image: url('../images/dreams-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 250px 0px
}

.bg-slide-3 { 
  background-image: url('../images/module.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 250px 0px
}

.slider-info {
  top: 100px;
  background: rgb(27 4 48 / 60%);
  padding: 40px 100px;
  border-right: $onbase-color 10px solid;
}

.slider-info-2 {
  top: 100px;
  background: rgb(27 4 48 / 60%);
  padding: 40px 100px;
  border-left: $onbase-color 10px solid;
}

.title-slider {
  font-weight: 600;
}

//AboutUs

.img-shape {
  border-left: 30px;
  border-bottom: 30px;
  border-top: 0px;
  border-right: 0px;
  border-color: $onbase-color;
  border-style: solid;
  border-radius: 36px;
}


//MidSection
.bg-dreams {
  height: 350px;
  background-image: url('../images/dreams-background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}



.bg-method {
  height: 350px;
  background-image: url('../images/meth.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-module {
  height: 350px;
  background-image: url('../images/module.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}


//Card
.card-transparent {
  background-color: transparent !important;
  border-radius: 24px !important;
  color: $light-gray !important;
  border: 4px solid $light-gray !important;
}



.form-control{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $onbase-color !important;
  background-color: transparent !important;
  background-clip: padding-box;
  border: 1px solid $onbase-color !important;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

::placeholder { color: $onbase-color !important;}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    $onbase-color !important;
}
::placeholder { /* Mozilla Firefox 4 to 18 */
  color:    $onbase-color !important;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    $onbase-color !important;
  opacity:  1;
}
::-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    $onbase-color !important;
}

.btn-outline-primary {
  color: $onbase-color !important;
  border-color: $onbase-color !important;
}

.btn-outline-primary:hover {
  color: $light-gray !important;
  border-color: $onbase-color !important;
  background-color: $onbase-color !important;
}

.btn-outline-primary:focus {
  box-shadow: none !important;
}

.navbar-light .navbar-nav .nav-link {
  color: $light-gray !important;
}

.navbar-light .navbar-toggler {
  color: $light-gray !important;
}

//ScrollUp Btn

.btn-scrollup {
  position: fixed;
  bottom: 10px;
  right: 30px;
  font-size: 24px;
}


//Contact Form 
div[role='alert'] {
  color: red;
  margin-top: 1em;
}
