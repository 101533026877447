$base-color: #1B0430;
$onbase-color: #F11146;
$light-gray: #F0F0F0;
$dark-gray: #909090;
$medium-gray: #D3D3D3;
$success-color:#A3DD55;
$warning-color:#F8DF1D;
$danger-color: #E80707;
$base-gradient: linear-gradient(180deg, rgba(27,4,48,1) 13%, rgba(27,4,48,0) 68%);

$primary-font: 'Raleway', sans-serif;