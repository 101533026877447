@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600&display=swap");
.info {
  background: DarkGray;
  box-shadow: 0 0 1px rgba(169, 169, 169, 0.25);
  color: #fff;
}

.alert {
  background: DarkRed;
  box-shadow: 0 0 1px rgba(139, 0, 0, 0.25);
  color: #fff;
}

.success {
  background: DarkGreen;
  box-shadow: 0 0 1px rgba(0, 100, 0, 0.25);
  color: #fff;
}

body {
  font-family: "Raleway", sans-serif !important;
}

#root {
  background-color: #1B0430;
}

h1, h2, h3, h4, h5, p {
  font-family: "Raleway", sans-serif;
  color: #F0F0F0;
}

a {
  color: #F0F0F0 !important;
}

a:hover {
  color: #F11146 !important;
}

a:active {
  color: #F11146 !important;
}

.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show > .nav-link {
  color: #F11146 !important;
}

.bg-base {
  background: #1B0430;
}

.bg-onbase {
  background: #F11146;
}

.bg-lightgray {
  background: #F0F0F0;
}

.bg-mediumgray {
  background: #D3D3D3;
}

.bg-darkgray {
  background: #909090;
}

.bg-base-gradient {
  background: linear-gradient(180deg, #1b0430 13%, rgba(27, 4, 48, 0) 68%);
}

.color-base {
  color: #1B0430;
}

.color-onbase {
  color: #F11146;
}

.color-lightgray {
  color: #F0F0F0;
}

.color-mediumgray {
  color: #D3D3D3;
}

.color-darkgray {
  color: #909090;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-button-next, .swiper-button-prev {
  color: #F0F0F0 !important;
}

.swiper-pagination-bullet-active {
  background-color: #F0F0F0 !important;
}

.bg-slide {
  background-image: url("../images/SlideOne.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 250px 0px;
}

.bg-slide-2 {
  background-image: url("../images/dreams-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 250px 0px;
}

.bg-slide-3 {
  background-image: url("../images/module.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 250px 0px;
}

.slider-info {
  top: 100px;
  background: rgba(27, 4, 48, 0.6);
  padding: 40px 100px;
  border-right: #F11146 10px solid;
}

.slider-info-2 {
  top: 100px;
  background: rgba(27, 4, 48, 0.6);
  padding: 40px 100px;
  border-left: #F11146 10px solid;
}

.title-slider {
  font-weight: 600;
}

.img-shape {
  border-left: 30px;
  border-bottom: 30px;
  border-top: 0px;
  border-right: 0px;
  border-color: #F11146;
  border-style: solid;
  border-radius: 36px;
}

.bg-dreams {
  height: 350px;
  background-image: url("../images/dreams-background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-method {
  height: 350px;
  background-image: url("../images/meth.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-module {
  height: 350px;
  background-image: url("../images/module.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.card-transparent {
  background-color: transparent !important;
  border-radius: 24px !important;
  color: #F0F0F0 !important;
  border: 4px solid #F0F0F0 !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #F11146 !important;
  background-color: transparent !important;
  background-clip: padding-box;
  border: 1px solid #F11146 !important;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

::placeholder {
  color: #F11146 !important;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #F11146 !important;
}

::placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #F11146 !important;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #F11146 !important;
  opacity: 1;
}

::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #F11146 !important;
}

.btn-outline-primary {
  color: #F11146 !important;
  border-color: #F11146 !important;
}

.btn-outline-primary:hover {
  color: #F0F0F0 !important;
  border-color: #F11146 !important;
  background-color: #F11146 !important;
}

.btn-outline-primary:focus {
  box-shadow: none !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #F0F0F0 !important;
}

.navbar-light .navbar-toggler {
  color: #F0F0F0 !important;
}

.btn-scrollup {
  position: fixed;
  bottom: 10px;
  right: 30px;
  font-size: 24px;
}

div[role=alert] {
  color: red;
  margin-top: 1em;
}

